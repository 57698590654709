.CardLogin {
    width: 500px;
    height: 85vh;
    background-color: #5a96d6;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: solid 2px #94bae5;
}

.CardLogin img {
    max-width: 100px;
    align-self: center;
    padding-bottom: 30px;
}

.CardLogin input {
    height: 50px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 300;
    padding: 10px;
    color: #1f4b85;
    font-weight: 600;
    border: solid 2px #1f4b85;
    background-color: #c6d9f1;
    border-radius: 3px;
}

.CardLogin input:hover {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: #94bae5;
    border-radius: 3px;
}

.CardLogin input:focus {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: #94bae5;
    border-radius: 3px;
    outline: none;
}

.CardLogin input::before {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: #94bae5;
    border-radius: 3px;
    outline: none;
}

.CardLogin input::placeholder {
    color: #194575;
}

.CardLogin button {
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #e5edf9;
    background-color: #1f4b85;
    font-size: 18px;
    border: none;
    border-radius: 2px;
}

.CardLogin button:hover {
    background-color: #1e416e;
}

.CardLogin button:active {
    background-color: #1e416e;
}

.CardLogin p,
.CardLogin a {
    align-self: center;
    margin: 20px;
}

.CardLogin a {
    align-self: center;
    margin: 20px;
    color: white;
    font-size: 18px;
}

.CardLogin span {
    cursor: pointer;
}

.MainLogin {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}