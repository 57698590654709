.BG1404 {
    background: linear-gradient(0deg, #ffffff 0%, #c9c9c9 100%);
    width: 100vw;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BG2404 {
    background: white;
    width: 100vw;
    height: calc((100vh - 55vh) - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #000000;
}

.BG2404 p {
    color: black;
    font-size: 24px;
    font-weight: 500;
}

.BG1404 p {
    color: black;
    font-size: 20vw;
    font-weight: 500;
}

.caution-tape {
    position: relative;
    height: 50px;
    width: 100vw;
    background: repeating-linear-gradient(45deg, #000000, #000000 15px, #fbff00 15px, #fbff00 30px);
}

.BTNS404 {
    display: flex;
}

.BTNS404 a,
.BG2404 button {
    width: 150px;
    height: 30px;
    margin: 10px;
    background-color: #c9c9c9;
    border: none;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}