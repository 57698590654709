.Centred {
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
    min-height: calc(100vh - 90px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.anun {
    width: calc(100vw / 3);
    height: 100vh;
}

.content {
    width: calc(100vw / 3);
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Centred .content div svg {
    width: 300px;
    max-width: 300px;
    height: 300px;
    position: absolute;
}

.Centred .content div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Time {
    padding: 75px;
}

.content p {
    max-width: calc(100vw / 3 - 20px);
    word-wrap: break-word;
}

@media screen and (max-width: 1003px) {
    .Centred {
        flex-direction: column;
    }    
}