.MainHome {
    display: flex;
    align-items: center;
}

.LineHomeTextos {
    width: 90%;
    height: 2px;
    background-color: #194575;
}

.STextosHome {
    width: 35%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 60px;
}

.ItensTextosHome {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 90%;
    padding-left: 15px;
}

.ItensTextosHome h6 {
    font-size: 16px;
    padding-bottom: 7px;
}

.SliderHome {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.itenscarrosel {
    display: flex;
}



.SImageHome {
    width: 65%;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.SImageHome img{
    max-width: 90%;
    max-height: 90%;
}

@media screen and (max-width: 1180px) {

    .itenscarrosel {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .STextosHome {
        width: 90%;
        height: calc(100vh - 90px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .itenscarrosel {
        display: flex;
        flex-direction: column-reverse;
    }

    .SImageHome {
        width: 100%;
        max-height: 100vw;
    }

    .SImageHome img{
        max-width: 90%;
        max-height: 90%;
    }
}