.FotosProjetos {
    padding: 5px;
    max-width: calc(95vw / 2);
}

iframe {
    margin: 10px;
    width: calc(95vw / 2);
    height: 800px;
    height: 27vw;
}

@media screen and (max-width:1180px) {
    .MainViwProjetos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .FotosProjetos {
        padding: 5px;
        max-width: 95vw;
    }

    iframe {
        margin: 10px;
        width: 95vw;
        height: 54vw;
    }
}

.sketchfab-embed-wrapper p {
    font-size: 13px;
    font-weight: normal;
    margin: 5px;
    color: #4A4A4A;
}

.sketchfab-embed-wrapper a {
    font-weight: bold;
    color: #1CAAD9;
}

.Video-3D {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderDrop {
    background-color: #1f4b85;
    height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin: 0 15px;
    gap: 30px;
}

.Drop {
    width: 100%;
}