.CardLogin2 {
    width: 500px;
    height: 50vh;
    background-color: #5a96d6;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: solid 2px #94bae5;
    align-items: center;
    justify-content: center;
}

.CardLogin2 p {
    align-self: center;
    margin: 20px;
}

.CardLogin2 a {
    align-self: center;
    margin: 0px;
    color: white;
    font-size: 18px;
}