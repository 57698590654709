.SideNavBar {
    background-color: #1f4b85;
    width: 100px;
    height: 100vh;
}

ul {
    list-style: none;
}

.SideNavBar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    margin-top: 15px;
    padding: 0;
}

.SideNavBar {
    background-color: #1f4b85;
    width: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.SideNavBar svg {
    fill: white;
align-self: center;
    width: 30px;
    margin-bottom: 20px;
}

.LineSideNavBar {
    width: 90%;
    height: 2px;
    background-color: #14243d;
    margin-bottom: 10px;
    margin-top: 10px;
}