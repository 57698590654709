.portifolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 1vw;
    margin: 20px;
}

.ImageProjeto {
    transition: 0.2s;
    max-width: calc(95vw / 3 - 25px);
    transition: .5s ease;
    backface-visibility: hidden;
}

.min-prj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.text {
    color: white;
    font-size: 1.5vw;
    padding-bottom: 10px;
    padding-top: 10px;
}

.text-s {
    text-align: center;
    width: 100%;
    background-color: #194575;
}

@media screen and (max-width: 840px) {
    .text {
        color: white;
        font-size: 2vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 2 - 25px);
    }
}

@media screen and (max-width: 751px) {
    .text {
        color: white;
        font-size: 3vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 2 - 30px);
    }
}

@media screen and (max-width: 500px) {
    .text {
        color: white;
        font-size: 4vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 1 - 35px);
    }
}