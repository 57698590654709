.map iframe {
    border: 0;
}

.LineContato {
    width: 90%;
    height: 2px;
    background-color: #194575;
}

.MsgSBV {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
}

.MsgSBV img {
    max-width: 35vw;
    clip-path: inset(5% 0 7% 0);
    overflow: hidden;
    padding: 10px;
    margin: 0;
}

.MsgSBV span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 3vw;
    font-weight: 800;
}

.IISecContato {
    display: flex;
    justify-content: space-around;
    height: 100%;
}

.TextosContato {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: start;
    margin-top: 150px;
    margin-bottom: 200px;
}

.TextosContato p {
    width: 85%;
}

#FormContact {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#FormContact form {
    width: 390px;
    display: flex;
    flex-direction: column;
}

#FormContact form label span {
    color: red;
}

#FormContact form label {
    padding-top: 10px;
    padding-bottom: 2px;
}

#FormContact form input,
#FormContact form textarea {
    padding: 10px;
    color: #1f4b85;
    font-weight: 600;
    border: solid 2px #1f4b85;
    background-color: #c6d9f1;
    border-radius: 3px;
}

#FormContact form input:hover,
#FormContact form textarea:hover {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
}

#FormContact form input:disabled,
#FormContact form textarea:disabled {
    padding: 10px;
    border: solid 2px #0e213a;
    background-color: #8295ac;
    border-radius: 3px;
}

#FormContact form input:focus,
#FormContact form textarea:focus {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: #94bae5;
    border-radius: 3px;
    outline: none;
}

#FormContact form input::before,
#FormContact form textarea::before {
    padding: 10px;
    color: #1f4b85;
    border: solid 2px #1f4b85;
    background-color: #94bae5;
    border-radius: 3px;
    outline: none;
}

#FormContact form input::placeholder {
    color: #194575;
}

#FormContact button {
    color: #e5edf9;
    background-color: #1f4b85;
    width: 290px;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 100px;
    font-size: 18px;
    border: none;
    border-radius: 2px;
}

#FormContact button:hover {
    background-color: #1e416e;
}

#FormContact button:active {
    background-color: #1e385c;
}

@media screen and (max-width: 1188px) {
    .TextosContato {
        width: 60vw;
    }
}

@media screen and (max-width: 854px) {
    .TextosContato {
        width: 70vw;
    }
}

@media screen and (max-width: 832px) {
    #FormContact {
        width: 100%;
    }

    .IISecContato {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
    }

    .TextosContato {
        width: 30vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start;
        margin-top: 0px;
        margin-bottom: 50px;
        width: 100%;
    }

    .TextosContato p {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 652px) {
    .MsgSBV {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 100%;
    }
    
    .MsgSBV img {
        max-width: 50vw;
        clip-path: inset(5% 0 7% 0);
        overflow: hidden;
        padding: 10px;
        margin: 0;
    }
    
    .MsgSBV span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 3vw;
        font-weight: 800;
    }
}

@media screen and (max-width: 452px) {
    #FormContact form {
        width: 100%;
    }

    .IISecContato {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
    }

    .TextosContato {
        width: 30vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start;
        margin-top: 0px;
        margin-bottom: 50px;
        width: 100%;
    }

    .TextosContato p {
        margin-bottom: 5px;
    }
}
