.left {
    width: 100vw;
    height: 100vh;
}

.rig {
    width: 100%;
    height: auto;
    background-color: red;
}

.Top {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dow {
    font-size: 12px;
}

.leftBT,
.leftBB{
    display: flex;
}

.Vx,
.V0y,
.Vy,
.S {
    width: calc(100vw / 2 - 50px);
    height: calc((50vh - 100px + 50px) / 2 - 50px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 2px solid white;
}

.H-vel input,
.H-ang input,
.H-g input {
 width: 50px;
 height: 25px;
 padding: 5px;
}

.H-vel,
.H-ang,
.H-g {
margin: 2px;
}

.Vx,
.V0y {
    margin-top: 25px;
    margin-bottom: 12.5px;
}

.Vy,
.S {
    margin-top: 12.5px;
    margin-bottom: 25px;
}

.Vy,
.Vx {
    margin-left: 25px;
    margin-right: 12.5px;
}

.V0y,
.S {
    margin-left: 12.5px;
    margin-right: 25px;
}


.Vx span,
.Vy span,
.V0y span,
.S span{
    margin: 1px;
}

.card-grafico {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grafico {
    height: 80%;
    aspect-ratio: 2/1;
    /* border: 1px solid black; */
}

canvas {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
}