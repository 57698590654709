.Main-Horario {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Horarios {
    width: 740px;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Horarios div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Horarios div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.D_A {
    max-width: 50px;
}

.D_A div {
    max-width: 50px;
}

.Aula {
    width: 100px;
    max-width: 100px;
    height: 35px;
    display: flex;
    justify-content: start;
    margin: 10px 0;
}

.card-dia {
    margin: 5px;
    max-width: calc(100% / 5 - 20px);
}

.card-dia div {
    max-width: 100%;
}

h2 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 695px) {
    .Horarios {
        font-size: 3vw;
        max-width: 100vw;
        /* background-color: black; */
    }
}

.GEO {
    background-color: red;
}

.BIO {
    background-color: aqua;
}

.TRILHA {
    background-color: rgb(196, 222, 48);
}

.PÓS_M {
    background-color: blue;
}

.ELETIVA {
    background-color: blueviolet;
}

.QUIM {
    background-color: chocolate;
}

.HIST {
    background-color: cornflowerblue;
}

.PORT {
    background-color: darksalmon;
}

.FIS {
    background-color: lightgreen;
}

.SOC {
    background-color: darkmagenta;
}

.ES_ORI {
    background-color: deeppink;
}

.EF {
    background-color: crimson;
}

.MAT {
    background-color: darkgoldenrod;
}

.FILO {
    background-color: darkgreen;
}

.PV {
    background-color: fuchsia;
}

.ARTES {
    background-color: darkblue;
}

.horas {
    width: 150px;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.horas1 {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.horas2 {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}