.Main-calc {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.Nav-Calc {
    width: calc(30vw - 10px);
    max-width: 200px;
    height: 80vh;
    background-color: #18487a;
    border-radius: 3px;
}

.Card-Calcs {
    width: calc(70vw - 10px);
    max-width: 500px;
    height: 80vh;
    background-color: #18487a;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 3px;
}

.Nav-calc {
    width: 100%;
    height: calc(10% - 5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.Indt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.Nav-calc div button {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    padding: 10px;
}

.Nav-calc div button:hover {
    background-color: rgba(255, 255, 255, 0.071);
    border-radius: 5px;
}

.Nav-calc div button:active {
    background-color: rgba(255, 255, 255, 0.19);
    border-radius: 5px;
}

.Display-calc {
    width: 100%;
    height: calc(25% - 5px);
    display: flex;
    flex-direction: column;
}

.Keys-calc {
    width: 100%;
    height: calc(65% - 4px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.L-calc {
    width: calc(100% / 4 - 5px);
}

.D-btn {
    width: 100%;
    height: calc(100% / 6 - 5px);
    margin: 5px 0;
}

.D-btn button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.112);
    font-size: 26px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.D-btn button img {
    max-height: 35%;
}

.D-btn button img.xls {
    max-height: 70%;
}

.D-btn button:hover {
    background-color: rgba(255, 255, 255, 0.148);
}

.D-btn button:active {
    background-color: rgba(255, 255, 255, 0.241);
}

.D-Top {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    width: 100%;
    height: 40%;
    font-size: 22px;
}

.D-Bottom {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    width: 100%;
    height: 60%;
    font-size: 64px;
}

.D-Bottom .D-Bottom-Ipn {
    text-align: right;
    width: 100%;
    height: 100%;
    font-size: 64px;
    border: none;
    background-color: transparent;
    color: white;
}

.D-Bottom .D-Bottom-Ipn:focus {
    outline: none;
}