.cabeçalho {
    font-size: 36px;
    justify-content: space-between;
    height: 90px;
    display: flex;
    background-color: #18487a;
}

img {
    margin: 10px;
    max-width: 60px;
}

.marca {
    justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-list {
    list-style: none;
    display: flex;
    color: white;
    padding: 15px 32px;
    justify-content: space-around;
    align-items: center;
    width: 250px;
    height: 100%;
}

.nav-list li {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 970px) {
    .meu-nome {
        font-size: 3.5vw;
    }
}

@media screen and (max-width: 660px) {
    .meu-nome {
        display: none;
    }
}

a[data-descr] {
    position: relative;
    text-decoration: underline;
    color: #3271b6;

}

a[data-descr]:hover::after {
    content: attr(data-descr);
    position: absolute;
    left: -15px;
    top: 50px;
    max-width: 150px;
    border: 1px #aaaaaa solid;
    border-radius: 2px;
    background-color: #e5edf9;
    padding: 4px;
    color: #14243d;
    font-size: 14px;
    z-index: 1;
}