.Textoprincipal {
    padding: 50px;
    font-size: 18px;
}

.FristInfos {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.FristInfos img {
    max-width: 100px;
    border-radius: 50%;
    margin-left: 50px
}

.FristInfos p {
    font-size: 24px;
}

.FristInfos button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    background-color: #5a96d6;
    position: relative;
    left: -30px;
    bottom: 15px;
}

.FristInfos button:hover {
    background-color: #255ea4;
}

.FristInfos button:active {
    background-color: #1e416e;
}

.Sec {
    width: 100%;
}

.ContacInfos nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    font-size: 20px;
    margin-top: 50px;
    background-color: #1f4b85;
    padding: 20px;
}

.ContacInfos main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    font-size: 20px;
    background-color: #255ea4;
    padding: 20px;
}

.ContacInfos nav svg {
    width: 20px;
    height: 20px;
}

.ContacInfos nav button {
    border: none;
    background-color: transparent;
}

.ContacInfos nav button:active {
    transform: scale(0.9);
}

.ContacInfos main div {
    margin-bottom: 20px;
}