.produtos {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 1vw;
    margin: 20px;
}

.min-prod {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.ImageProduto {
    transition: 0.2s;
    max-width: calc(95vw / 3 - 25px);
    min-width: calc(95vw / 3 - 25px);
    transition: .5s ease;
    backface-visibility: hidden;
}

.text-P {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.text-s button {
    min-width: 200px;
    height: 30px;
    background-color: #194575;
    border: none;
    color: white;
}

.text-s button:hover{
    min-width: 200px;
    height: 30px;
    background-color: #214f81;
    border: none;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 840px) {
    .ImageProduto {
        max-width: calc(95vw / 2 - 25px);
    }
}

@media screen and (max-width: 751px) {
    .ImageProduto {
        max-width: calc(95vw / 2 - 30px);
    }
}

@media screen and (max-width: 500px) {
    .ImageProduto {
        max-width: calc(95vw / 1 - 35px);
    }
}