@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, Inter, arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3271B6;
  color: white;
}


/* nice scroll bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #3271b6;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: #a9a9a9a9;
  border-radius: 3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Inter', Arial;
}

code {
  font-family: Inter, arial;
}

svg {
  fill: white;
}

main {
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
}

.NotOverFlow {
  overflow: hidden;
}

/* styles.css */
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1e416e;
  padding: 10px;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 10px #14243d;
}

.cookie-consent p {
  margin: 0;
}

.cookie-consent button {
  color: #e5edf9;
  background-color: #1f4b85;
  width: 290px;
  height: 40px;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.cookie-consent button:hover {
  background-color: #255ea4;
}

.cookie-consent button:active {
  background-color: #1e385c;
}

.cookie-consent a {
  color: #ffffff;
}